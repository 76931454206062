import React, { useEffect } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import ScrollReveal from "scrollreveal"

const Instructions = () => {
  useEffect(() => {
    ScrollReveal().reveal(".saas-foo-left", {
      delay: 0,
      distance: "50px",
      easing: "ease-in-out",
      mobile: false,
      origin: "left",
      duration: 400,
      interval: 50,
      opacity: 0,
      init: false,
      // reset: true,
    })
  })
  const data = useStaticQuery(graphql`
    query {
      instruction: file(relativePath: { eq: "saas/2-1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="instructions-box">
      <div className="absolute-title saas-foo">Benefits of BBA</div>
      <div className="instructions">
        <div className="instruction-img-bg saas-foo-left">
          <Img
            fluid={data.instruction.childImageSharp.fluid}
            alt=""
            className="instruction-img saas-foo-left"
          />
        </div>

        <ul className="instruction-list">
          <li>
            <svg className="icon saas-foo-left">
              <use href="#iconquickly" />
            </svg>
            <div className="instruction-list-info saas-foo-left">
              Build your website in a few easy steps.
            </div>
          </li>
          <li>
            <svg className="icon saas-foo-left">
              <use href="#iconbianzu23" />
            </svg>
            <div className="instruction-list-info saas-foo-left">
              Import your product details from multiple platforms, quickly and
              reliably.
            </div>
          </li>
          <li>
            <svg className="icon saas-foo-left">
              <use href="#iconbianzu24" />
            </svg>
            <div className="instruction-list-info saas-foo-left">
              Track and analyze your website traffic from different marketing
              channels.
            </div>
          </li>
          <li>
            <svg className="icon saas-foo-left">
              <use href="#iconbianzu25" />
            </svg>
            <div className="instruction-list-info saas-foo-left">
              Email temperate to retarget your potential customers.
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default Instructions
