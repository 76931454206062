import React from "react"
import { Row, Col } from "react-bootstrap"

const QuickBuild = () => (
  <div className="saas-quickly-build">
    <div className="title saas-foo">YOUR OWN BUSINESS, YOUR OWN WEBSITE.</div>
    <div className="text saas-foo">
      Use BBA to build your own platform. manage your own custmers, run your own
      brand, analyze your own traffic.
    </div>
    <Row xs={1} sm={3} md={3} lg={3} xl={3}>
      <Col>
        <div className="saas-item-box saas-foo">
          <div className="saas-item saas-foo">
            <svg className="icon saas-foo">
              <use href="#iconrestraint" />
            </svg>
            <div className="item-title saas-foo">Your Brand</div>
            <div className="item-text saas-foo">
              You don't have to compete with hundreds of other sellers on
              pricing but focus on quality and service which leads to good brand
              awareness of your business.
            </div>
          </div>
        </div>
      </Col>
      <Col>
        <div className="saas-item-box saas-foo">
          <div className="saas-item saas-foo">
            <svg className="icon saas-foo">
              <use href="#icontraffic" />
            </svg>
            <div className="item-title saas-foo">Your Data</div>
            <div className="item-text saas-foo">
              It's very hard for platform sellers to acquire and manage the data
              of their own customers.BBA helps the business owner analyze
              customer data to get a full & clear understanding of their
              potential customers.
            </div>
          </div>
        </div>
      </Col>
      <Col>
        <div className="saas-item-box saas-foo">
          <div className="saas-item saas-foo">
            <svg className="icon saas-foo">
              <use href="#iconliuliang" />
            </svg>
            <div className="item-title saas-foo">Full Customizable</div>
            <div className="item-text saas-foo">
              People have their own personalities. So does your brand.BBA offer
              you multiple themes with sufficient variant to design a website
              that fits you.
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
)

export default QuickBuild
